body {
    font-family: 'Helvetica', sans-serif;
    font-size: large;
    background-color: #F7F7F7;
}

body * {
    font-family: 'Helvetica', sans-serif;
}

input {
    font-family: 'Helvetica', sans-serif;
    font-size: large;
    padding: 2px;
}

select {
    font-family: 'Helvetica', sans-serif;
    font-size: large;
}

input:focus {
    outline: none !important;
    border: 2px solid #F58242;
    box-shadow: 0 0 10px #AAAAAA;
}

fieldset {
    box-shadow: 0 0 8px #CCCCCC;
    margin-top: 8px;
    border-color: #D2D2D2;
    border-radius: 4px;
}

fieldset legend {
    color: #777777;
    font-weight: bold;
    text-shadow: 0 0 5px #DDDDDD;
}

h2 {
    color: #F58242;
    font-weight: bold;
    font-size: xx-large;
    margin-top: 0;
    margin-bottom: 10px;
    text-decoration: underline #888888;
    text-shadow: 0 0 10px #CCCCCC;

}

.non_disp {
    display: none;
}

.outter_Box {
    display: flex;
    justify-content: center;
    width: 100%;

}

.inner_Box {
    width: 935px;
    display: block;
    margin-bottom: 15px;
}

.inner_Box_small {
    width: 650px;
    display: block;
    margin-bottom: 15px;
}

.centered_Button {
    display: block;
    margin: auto;
}

.position_table {
    border-collapse: collapse;
}

.position_table * {
    border: 1px solid;
}

.position_table tr:nth-child(odd) {
    background-color: #FCD5B4;
}

.position_table tr:first-child {
    background-color: #F58242;
}

.center_text {
    text-align: center;
}

.center_content {
    text-align: center;
}

.color_orange_strong {
    background-color: #F58242;
}

.color_orange_medium {
    background-color: #F9B37C;
}

.color_orange_light {
    background-color: #FCD5B4;
}

.fontcolor_orange_strong {
    color: #F58242;
}

.edit_button {
    margin: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    border-style: none;
    background-color: #D8D8D8;
    min-height: 24px;
    font-size: 15px;
}

.edit_button:hover {
    background: #B2B2B2;
}

.edit_button_td {
    padding: 0;
    margin: 0;
    border: 0;
}

.pretty_button {
    background-color: #F9B37C;
    border-color: #777777;
    font-size: 20px;
    border-radius: 4px;
    padding: 2px;
}

.pretty_button:hover:enabled {
    background-color: #F58242;

}

.aligncontent_center {
    align-content: center;
}


.align_left {
    text-align: left;
}

.align_center {
    text-align: center;
}

.align_right {
    text-align: right;
}

.font_xxl {
    font-size: xx-large;
}

.margin5 {
    margin: 5px;
}

.margin10 {
    margin: 10px;
}

.bottom_margin25 {
    margin-bottom: 25px;
}
.bottom_margin10 {
    margin-bottom: 10px;
}


.top_margin20 {
    margin-top: 20px;
}
.top_margin15 {
    margin-top: 15px;
}
.top_margin10 {
    margin-top: 10px;
}

.export_button_margin {
    margin-top: 24px;
}

.rev_button_margin {
    margin-top: 13px;
}


.w900px {
    width: 900px;
}

.w100 {
    width: 100%;
}

.w97 {
    width: 97%;
}

.w95 {
    width: 95%;
}

.w93 {
    width: 93%;
}

.w90 {
    width: 90%;
}

.w85 {
    width: 80%;
}

.w80 {
    width: 80%;
}

.w75 {
    width: 75%;
}

.w70 {
    width: 70%;
}

.w66 {
    width: 66%;
}

.w60 {
    width: 60%;
}

.w57 {
    width: 57%;
}

.w50 {
    width: 50%;
}

.w45 {
    width: 45%;
}

.w40 {
    width: 40%;
}

.w35 {
    width: 35%;
}

.w34 {
    width: 34%;
}

.w33 {
    width: 33%;
}

.w30 {
    width: 30%;
}

.w29 {
    width: 29%;
}

.w28 {
    width: 28%;
}

.w25 {
    width: 25%;
}

.w20 {
    width: 20%;
}
.w18_5 {
    width: 18.5%;
}
.w15 {
    width: 15%;
}

.w10 {
    width: 10%;
}

.w9 {
    width: 9%;
}

.w6 {
    width: 6%;
}

.w5 {
    width: 5%;
}

.w3 {
    width: 3%;
}
.w1_5 {
    width: 1.5%;
}

.h20px {
    height: 20px;
}
.h17px {
    height: 17px;
}
.h140px {
    height: 140px;
}

.h100 {
    height: 100%;
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 450px;
    background-color: #F58242;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

input[type='checkbox'] {

    width: 20px;
    height: 20px;

}

input[type='checkbox']:checked {
    background: #F58242;
}



