#inner-loader {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
  }
  #inner-loader img
  {
    vertical-align: middle;
  }

  #inner-loader{
    animation: blinker 1s linear infinite;
  }
  
  @keyframes blinker {
    80% {
      opacity: 0;
    }
  }